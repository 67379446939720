<template>
  <i18n-t :keypath="`activity_logs.${actionType}`">
    <template
      v-for="(value, slotName) in normalizedSlotData"
      :key="slotName"
      #[slotName]
    >
      <b class="text-secondaryDark font-bold">{{ value }}</b>
    </template>
  </i18n-t>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  actionType: {
    type: String,
    default: null,
  },
  data: {
    type: Object,
    default: () => ({}),
  },
})

const slotData = computed(() => {
  const actionSlots = {
    WORKSPACE_CREATE: ["name"],
    WORKSPACE_RENAME: ["old_name", "new_name"],
    WORKSPACE_USER_ADD: ["identifier", "role"],
    WORKSPACE_USER_INVITE: ["inviteeEmail", "inviteeRole"],
    WORKSPACE_USER_INVITE_REVOKE: ["inviteeEmail", "inviteeRole"],
    WORKSPACE_USER_INVITE_ACCEPT: ["identifier", "role"],
    WORKSPACE_USER_REMOVE: ["identifier"],
    WORKSPACE_USER_ROLE_UPDATE: ["identifier", "old_role", "new_role"],
    COLLECTION_CREATE: ["title"],
    COLLECTION_RENAME: ["old_title", "new_title"],
    COLLECTION_IMPORT: ["rootCollectionCount"],
    COLLECTION_DELETE: ["title"],
    COLLECTION_DUPLICATE: ["parentID", "parentTitle"],
    REQUEST_CREATE: ["title"],
    REQUEST_RENAME: ["old_title", "new_title"],
    REQUEST_DELETE: ["title"],
    ACTIVITY_LOG_DELETE: [],
  }

  const requiredSlots = actionSlots[props.actionType] || []

  const slots = {}
  requiredSlots.forEach((slotName) => {
    if (slotName === "identifier") {
      slots[slotName] = props.data.displayName || props.data.email || ""
    } else {
      slots[slotName] = props.data[slotName] || ""
    }
  })

  return slots
})

// Map to ensure i18n slot names remain static and match i18n variable names
const slotNameMap = {
  identifier: "user",
  userUid: "user",
  inviteeEmail: "email",
  inviteeRole: "inviteeRole",
  role: "role",
  old_name: "old_name",
  new_name: "new_name",
  old_title: "old_title",
  new_title: "new_title",
  old_role: "old_role",
  new_role: "new_role",
  parentID: "id",
  parentTitle: "parentTitle",
  rootCollectionCount: "count",
}

// Normalize slot names for i18n template
const normalizedSlotData = computed(() => {
  const normalized = {}

  Object.entries(slotData.value).forEach(([key, value]) => {
    const normalizedKey = slotNameMap[key] || key
    normalized[normalizedKey] = value
  })

  return normalized
})
</script>
