import {
  differenceInYears,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
} from "date-fns"

// Get time difference and group them into categories
export function getTimeDifferenceString(date: Date): string {
  const now = new Date()

  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  )
  const startOfDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  )

  const daysDifference = differenceInDays(startOfToday, startOfDate)

  if (daysDifference === 0) {
    return "today"
  } else if (daysDifference === 1) {
    return "yesterday"
  } else if (daysDifference < 7) {
    return `${daysDifference} days ago`
  } else if (daysDifference < 30) {
    const weeksDifference = differenceInWeeks(now, date)
    return `${weeksDifference} week${weeksDifference > 1 ? "s" : ""} ago`
  } else if (daysDifference < 90) {
    const monthsDifference = differenceInMonths(now, date)
    return `${monthsDifference} month${monthsDifference > 1 ? "s" : ""} ago`
  } else if (daysDifference < 180) {
    return "3 months ago"
  } else if (daysDifference < 365) {
    return "6 months ago"
  }
  const yearsDifference = differenceInYears(now, date)
  return `${yearsDifference} year${yearsDifference > 1 ? "s" : ""} ago`
}
