<template>
  <HoppSmartPlaceholder
    v-if="activityLogsLength === 0"
    :src="`/images/states/${colorMode.value}/time.svg`"
    :alt="t('empty.activity_logs')"
    :text="t('empty.activity_logs')"
  />
  <div v-for="(logs, date) in activityLogs" v-else :key="date">
    <details class="flex flex-col" open>
      <summary
        class="group flex min-w-0 flex-1 cursor-pointer items-center justify-between text-tiny text-secondaryLight transition focus:outline-none"
      >
        <span
          class="inline-flex items-center justify-center truncate px-4 py-2 transition group-hover:text-secondary"
        >
          <icon-lucide-chevron-right
            class="indicator mr-2 flex flex-shrink-0"
          />
          <span class="truncate capitalize-first">{{ date }}</span>
        </span>
      </summary>

      <div class="ml-6">
        <div
          v-for="log in logs"
          :key="log.id"
          class="border-l border-primaryDark pb-2 hover:bg-primaryLight hover:bg-opacity-10"
          :class="
            log.metadata
              ? 'bg-opacity-0'
              : 'bg-primaryLight hover:bg-primaryLight'
          "
        >
          <div class="flex flex-col p-2 gap-3 ml-2 group">
            <div v-if="log.metadata" class="text-secondaryDark mt-1">
              <div v-for="(data, actionType) in log.metadata" :key="actionType">
                <ActionMessage
                  v-if="data"
                  :action-type="actionType"
                  :data="data"
                />
              </div>
            </div>
            <div v-else class="mt-1">
              <p class="text-secondaryDark">
                {{ t("activity_logs.ACTIVITY_LOG_DELETE") }}
              </p>
            </div>
            <div class="flex justify-between mt-1">
              <div class="flex flex-col md:flex-row">
                <div class="font-bold">
                  <p v-if="log.actor.displayName">
                    {{ log.actor.displayName }} •
                  </p>
                  <p v-else>{{ log.actor.email }} •</p>
                </div>
                <span class="ml-1">
                  {{ getCreatedDate(log.createOn) }},
                  {{ getCreatedTime(log.createOn) }}
                </span>
              </div>
              <HoppButtonSecondary
                v-if="log.metadata && workspaceRole === TeamMemberRole.Owner"
                v-tippy="{ theme: 'tooltip' }"
                :title="t('team.delete_activity_log')"
                :icon="IconTrash2"
                class="!text-red-500 !p-0 ml-3 opacity-0 group-hover:opacity-100"
                @click="setActivityLogID(log.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </details>
  </div>

  <HoppSmartIntersection
    v-if="moreToFetch"
    class="h-2"
    @intersecting="fetchActivityLogs()"
  >
    <div v-if="error">
      <p class="text-xs text-center mt-10">
        {{ t("error.fetch_activity_logs") }}
      </p>
    </div>

    <HoppSmartSpinner v-if="fetching" class="mx-auto mt-2" />
  </HoppSmartIntersection>
</template>

<script setup lang="ts">
import { useI18n } from "@hoppscotch/common/composables/i18n"
import { useColorMode } from "@hoppscotch/common/composables/theming"
import { useToast } from "@hoppscotch/common/composables/toast"
import { GQLError } from "@hoppscotch/common/helpers/backend/GQLClient"
import { TeamMemberRole } from "@hoppscotch/common/helpers/backend/graphql"
import { WorkspaceService } from "@hoppscotch/common/services/workspace.service"
import { getTimeDifferenceString } from "@utils/date"
import { format } from "date-fns"
import { useService } from "dioc/vue"
import * as E from "fp-ts/Either"
import { pipe } from "fp-ts/lib/function"
import * as TE from "fp-ts/TaskEither"
import { computed, ref, watch } from "vue"
import IconTrash2 from "~icons/lucide/trash-2"
import {
  ActivityLogAddedSubscription,
  ActivityLogDeletedManySubscription,
  ActivityLogDeletedSubscription,
  ActivityLogsQuery,
} from "../../api/generated/graphql"
import { ActivityLogsService } from "../../services/activity-logs.service"
import ActionMessage from "./ActionMessage.vue"

const t = useI18n()
const toast = useToast()
const colorMode = useColorMode()

// Get created date and time
const getCreatedDate = (date: string) => format(new Date(date), "dd-MM-yyyy")
const getCreatedTime = (date: string) => format(new Date(date), "hh:mm a")

const take = 20
const cursor = ref<string>("")
const moreToFetch = ref(true)
const activityLogsService = useService(ActivityLogsService)
const activityLogs = ref<Record<string, ActivityLogsQuery["activityLogs"]>>({})

const activityLogsLength = computed(
  () => Object.values(activityLogs.value).flat().length
)

// Get workspace ID and role
const workspaceService = useService(WorkspaceService)

const workspaceID = computed(() =>
  workspaceService.currentWorkspace.value.type === "team"
    ? workspaceService.currentWorkspace.value.teamID
    : ""
)

const workspaceRole = computed(() =>
  workspaceService.currentWorkspace.value.type === "team"
    ? workspaceService.currentWorkspace.value.role
    : TeamMemberRole.Viewer
)

const getErrorMessage = (error: { type: string; error: Error }) => {
  if (error.type === "network_error") {
    return t("error.network_error")
  }
  return t("error.something_went_wrong")
}

// Fetch activity logs
const fetching = ref(false)
const error = ref<string | null>(null)

const fetchActivityLogs = async () => {
  if (fetching.value) return

  fetching.value = true
  error.value = null

  const result = await activityLogsService.getActivityLogs(
    workspaceID.value,
    cursor.value,
    take
  )

  if (E.isLeft(result)) {
    fetching.value = false
    error.value = getErrorMessage(result.left as { type: string; error: Error })
    toast.error(error.value)
    return
  }

  const logs = result.right.activityLogs

  // Update cursor and check if more data exists
  if (logs.length > 0) {
    cursor.value = logs[logs.length - 1].id

    let actionTimeTotal = 0
    logs.forEach((log) => {
      actionTimeTotal += log.actionTimes
    })

    moreToFetch.value = actionTimeTotal === take
  } else {
    moreToFetch.value = false
  }

  transformActivityLogs(logs)
  fetching.value = false
}

const transformActivityLogs = (logs: ActivityLogsQuery["activityLogs"]) => {
  logs.forEach((log) => {
    const onlyDate = getTimeDifferenceString(new Date(log.createOn))

    // Add new log to today's group, creating it as the first entry if absent.
    if (onlyDate === "today" && !activityLogs.value[onlyDate]) {
      activityLogs.value = {
        today: [log],
        ...activityLogs.value,
      }
    } else if (!activityLogs.value[onlyDate]) {
      activityLogs.value[onlyDate] = []
    }

    // Check for duplicates
    const logExists = activityLogs.value[onlyDate].some(
      (existingLog) => existingLog.id === log.id
    )

    if (logExists) return

    // Find correct position to insert based on timestamp
    const insertIndex = activityLogs.value[onlyDate].findIndex(
      (existingLog) => new Date(existingLog.createOn) < new Date(log.createOn)
    )

    if (insertIndex === -1) {
      activityLogs.value[onlyDate].push(log)
    } else {
      activityLogs.value[onlyDate].splice(insertIndex, 0, log)
    }
  })
}

// Delete activity log
const currentActivityLogID = ref("")

const setActivityLogID = async (id: string) => {
  currentActivityLogID.value = id
  await deleteActivityLogEntry()
}

const deleteActivityLogEntry = async () => {
  pipe(
    activityLogsService.deleteActivityLog(
      workspaceID.value,
      currentActivityLogID.value
    ),
    TE.match(
      () => {
        toast.error(t("error.delete_activity_log"))
      },
      () => {
        const logEntries = Object.values(activityLogs.value).flat()
        const deletedLog = logEntries.find(
          (log) => log.id === currentActivityLogID.value
        )
        if (deletedLog) deletedLog.metadata = null

        currentActivityLogID.value = ""
        toast.success(t("team.deleted_activity_log"))
      }
    )
  )()
}

// Setup activity log subscriptions
const cleanup = ref<(() => void) | null>(null)

const setupActivityLogSubscriptions = () => {
  const [addedStream, addedSubStream] =
    activityLogsService.runActivityLogAddedSubscription(workspaceID.value)
  const [deletedStream, deletedSubStream] =
    activityLogsService.runActivityLogDeletedSubscription(workspaceID.value)
  const [deletedManyStream, deletedManySubStream] =
    activityLogsService.runActivityLogDeletedManySubscription(workspaceID.value)

  const subscriptions = [
    // Handle new logs
    addedStream.subscribe({
      next: (
        result: E.Either<GQLError<string>, ActivityLogAddedSubscription>
      ) => {
        if (E.isLeft(result)) {
          toast.error(t("error.subscription_error", { error: result.left }))
          return
        }
        transformActivityLogs([result.right.activityLogAdded])
      },
    }),

    // Handle single log deletion
    deletedStream.subscribe({
      next: (
        result: E.Either<GQLError<string>, ActivityLogDeletedSubscription>
      ) => {
        if (E.isLeft(result)) {
          toast.error(t("error.subscription_error", { error: result.left }))
          return
        }

        const deletedLogId = result.right.activityLogDeleted
        const logEntries = Object.values(activityLogs.value).flat()
        const deletedLog = logEntries.find((log) => log.id === deletedLogId)
        if (deletedLog) deletedLog.metadata = null
      },
    }),

    // Handle bulk deletion
    deletedManyStream.subscribe({
      next: (
        result: E.Either<GQLError<string>, ActivityLogDeletedManySubscription>
      ) => {
        if (E.isLeft(result)) {
          toast.error(t("error.subscription_error", { error: result.left }))
          return
        }

        if (result.right.activityLogDeletedMany) {
          Object.values(activityLogs.value)
            .flat()
            .forEach((log) => {
              log.metadata = null
            })
          cursor.value = ""
          moreToFetch.value = true
        }
      },
    }),
  ]

  // Cleanup subscriptions
  return () => {
    subscriptions.forEach((sub) => sub.unsubscribe())
    addedSubStream.unsubscribe()
    deletedSubStream.unsubscribe()
    deletedManySubStream.unsubscribe()
  }
}

// Watch for workspace changes and manage subscription
watch(
  workspaceID,
  (newID) => {
    // Cleanup existing subscription if any
    if (cleanup.value) {
      cleanup.value()
      cleanup.value = null
    }

    if (newID) {
      cleanup.value = setupActivityLogSubscriptions()
    }
  },
  { immediate: true }
)

watch(
  workspaceID,
  async () => {
    activityLogs.value = {}
    cursor.value = ""
    await fetchActivityLogs()
  },
  { immediate: true }
)
</script>
