import { createHoppApp } from "@hoppscotch/common"
import { ExtensionInspectorService } from "@hoppscotch/common/platform/std/inspections/extension.inspector"
import { browserInterceptor } from "@hoppscotch/common/platform/std/interceptors/browser"
import { ExtensionInterceptorService } from "@hoppscotch/common/platform/std/interceptors/extension"
import { proxyInterceptor } from "@hoppscotch/common/platform/std/interceptors/proxy"
import { browserIODef } from "@hoppscotch/common/platform/std/io"
import { AgentInterceptorService } from "@hoppscotch/common/platform/std/interceptors/agent"
import { stdFooterItems } from "@hoppscotch/common/platform/std/ui/footerItem"
import { stdSupportOptionItems } from "@hoppscotch/common/platform/std/ui/supportOptionsItem"
import { SiteProtectionModule } from "./modules/siteProtection"
import {
  def as authDef,
  authRedirectErrorHoppModule,
} from "./platform/auth/auth.platform"
import { def as collectionsDef } from "./platform/collections/collections.platform"
import { def as environmentsDef } from "./platform/environments/environments.platform"
import { def as historyDef } from "./platform/history/history.platform"
import { def as settingsDef } from "./platform/settings/settings.platform"
import { CustomBannerService } from "./services/custom-banner.service"
import { LicensingService } from "./services/licensing.service"
import { InfraPlatform } from "@platform/infra/infra.platform"
import {
  ActivityLogsComponent,
  ActivityLogsHeaderItem,
} from "@platform/history/activity-logs.platform"
import { ActivityLogsService } from "./services/activity-logs.service"

createHoppApp("#app", {
  ui: {
    additionalFooterMenuItems: stdFooterItems,
    additionalSupportOptionsMenuItems: stdSupportOptionItems,
    additionalHistoryComponent: ActivityLogsComponent,
    additionalSidebarHeaderItem: ActivityLogsHeaderItem,
  },
  auth: authDef,
  io: browserIODef,
  sync: {
    environments: environmentsDef,
    collections: collectionsDef,
    settings: settingsDef,
    history: historyDef,
  },
  addedHoppModules: [authRedirectErrorHoppModule, SiteProtectionModule],
  addedServices: [LicensingService, CustomBannerService, ActivityLogsService],
  interceptors: {
    default: "browser",
    interceptors: [
      { type: "standalone", interceptor: browserInterceptor },
      { type: "standalone", interceptor: proxyInterceptor },
      { type: "service", service: ExtensionInterceptorService },
      { type: "service", service: AgentInterceptorService },
    ],
  },
  additionalInspectors: [
    { type: "service", service: ExtensionInspectorService },
  ],
  platformFeatureFlags: {
    exportAsGIST: false,
    hasTelemetry: false,
  },
  limits: {
    collectionImportSizeLimit: 50,
  },
  infra: InfraPlatform,
})
